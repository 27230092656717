import React from 'react';
import '../App.css';

import i18next from '../i18n'

const companiy_logos = [
  {
    name:"KLP",
    img:require("../images/partners/klp-logo-rgb.jpeg"),
  },
  {
    name:"DNB",
    img:require("../images/partners/DNB_ASA_logo.png"),
  },
  {
    name:"Gjensidige",
    img:require("../images/partners/gjensidige-logo.png"),
  },
  {
    name:"Circle K",
    img:require("../images/partners/cicle-k-logo.png"),
  },
  {
    name:"Nav",
    img: require("../images/partners/nav-logo-1200x.jpg")
  }
]

function Customers() {
  return (
    
    <div className='customers'>
        <header className="header">
          <h1>{i18next.t('pages.customers.title')}</h1>
          <div className='undertitle'>{i18next.t('pages.customers.undertitle')}</div>
        </header>
        <div className='partners'>
            {
              companiy_logos.map(image => (
                <div role="dialog" aria-modal="true" aria-label={image.name} key={image.name}>
                 <img alt={image.name+" "+i18next.t('common-words.logo')} key={image.name+"_"+i18next.t('common-words.logo')} src={image.img} />
                </div>
              ))
            }
          </div>
        
      </div>
   );
}

export default Customers;
